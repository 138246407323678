<template>
  <div class="">
    <navbar title="签到"></navbar>
    <div class="box">
      <!-- 大标题 -->
      <div class="title">
        <div class="title-detail">{{ title }}</div>
      </div>
      <!-- 分割线 -->
      <div class="line"></div>
      <!-- 签到部分 -->
      <div class="sign">
        <img @click="sign()" src="@/assets/img/sign-big.png" alt="" />
        <div>签到</div>
      </div>
    </div>
    <!-- <tabbar></tabbar> -->
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from "@/components/navBar.vue";
import { studySigninAdd, categoryLoad } from "@/api/api.js";
import { Toast, } from "vant";
export default {
  name: "",
  components: {
    navbar,
  },
  data() {
    return {
      // 覆盖签到用的 修改ID
      modifyId: "",
      title: "",
      //课程ID
      categoryId: "",
      userId: "",
    };
  },
  mounted() {
    // 截取本地url上的ID（activityId）
    this.interceptUrl();
    //获取课程名
    this.getCourseName();
  },
  methods: {
    // 截取本地url上的ID（activityId）
    interceptUrl() {
      // 截取url中的code方法
      var url = window.location.href;
      // var url = "http://localhost:8082/courseSign?categoryId=103009082583023768&userId=3&deviceCode=11111&code=030303";
      this.winUrl = url;
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var s = str.split("=");
        var strs = s[1].split("&");
        this.categoryId = strs[0];
        var strs2 = s[2].split("&");
        this.userId = strs2[0];
      }
    },

    //获取课程名
    getCourseName() {
      categoryLoad({
        // 课程ID
        id: this.categoryId,
      }).then((res) => {
        if (res.data.code == 0) {
          this.title = res.data.data.name
        } else {
          Toast(res.data.msg)
        }
      })
    },

    // 签到
    sign() {
      studySigninAdd({
        categoryId: this.categoryId,
        openId: localStorage.getItem('openId'),
        wxName: localStorage.getItem('nickname'),
        wxAvatarUrl: localStorage.getItem("headImgUrl"),
        userId: this.userId,
        // wxName:"测试昵称"
      }).then((res) => {
        if (res.data.code == 0) {
          Toast('签到成功')
        } else {
          Toast(res.data.msg)
        }
      });
    },
  },
};
</script>
<style scoped>
.title {
  width: 100%;
  height: 218px;
  position: relative;
}

.title-detail {
  width: 100%;
  text-align: center;
  position: absolute;
  color: #333333;
  font-size: 32px;
  font-family: Songti SC;
  font-weight: bold;
  top: 50%;
  transform: translateY(-50%);
}

.line {
  width: 100%;
  height: 20px;
  background: #faf9fb;
}

.sign {
  text-align: center;
}

.sign img {
  width: 300px;
  height: 300px;
  margin-top: 160px;
}

.sign div {
  margin-top: 40px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #505050;
}
</style>